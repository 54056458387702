/* eslint-disable */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import Login from "views/AuthPage/Components/Login.js";
import SecretLogin from "views/AuthPage/Components/SecretLogin.js";
import RegisterLow from "views/AuthPage/Components/RegisterLow.js";
import RegisterMedium from "views/AuthPage/Components/RegisterMedium.js";
import RegisterHigh from "views/AuthPage/Components/RegisterHigh.js";
import Privacy from "views/AuthPage/Components/Privacy.js";
// import KYC from "views/AuthPage/Components/KYC.js";
import GridContainer from "components/Grid/GridContainer.js";
import BlockchainCredentials from "views/AuthPage/Components/BlockchainCredentials.js";
import Account from "views/AuthPage/Components/Account";
import PasswordReset from "views/AuthPage/Components/PasswordReset";
// import { getUserSession } from "services/UserManagement.js";

const useStyles = makeStyles(styles);

function AuthPage(props) {
  const [step, setStep] = useState("LOGIN");
  const [previousRoute, setPreviousRoute] = useState("/");
  const [secretKey, setSecretKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [ethSecretKey, setEthSecretKey] = useState("");
  const [ethPublicKey, setEthPublicKey] = useState("");
  const [alias, setAlias] = useState("");
  const [type, setType] = useState("2");
  const [warning, setWarning] = useState("");

  const [merchantKey, setMerchantKey] = useState("");
  const [userKey, setUserKey] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const classes = useStyles();

  const { ...rest } = props;

  useEffect(() => {
    const fetchData = () => {
      //niftron merchant key - the creator key for any merchant
      const merchant = getParameterByName("merchantKey");
      if (merchant != null && merchant != "") {
        setMerchantKey(decodeURI(merchant));
      }

      //redirectUrl - on success redirect url
      const redirect = getParameterByName("redirectUrl");
      if (redirect != null && redirect != "") {
        setRedirectUrl(decodeURI(redirect));
      }

      //user account type - 0/1/2
      const type = getParameterByName("type");
      if (type != null && type != "") {
        setType(decodeURI(type));
      }

      //any special message or warning to user
      const warning = getParameterByName("warning");
      if (warning != null && warning != "") {
        setWarning(decodeURI(warning));
      }

      console.log(merchant, redirect, type, warning);
    };

    document.body.style.overflow = "scroll";
    fetchData();
  }, []);

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  return (
    <div>
      <div className={classes.container}>
        <GridContainer justify="center">
          <Link to={"/"}>
            <img
              height="50px"
              style={{ margin: "50px 0px 50px 0px" }}
              src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+FULL"
            />
          </Link>
        </GridContainer>
        {step === "LOGIN" && (
          <Login
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            history={props.history}
            warning={warning}
            redirectUrl={redirectUrl}
          />
        )}
        {step === "SECRETLOGIN" && (
          <SecretLogin
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            history={props.history}
            warning={warning}
            redirectUrl={redirectUrl}
          />
        )}
        {step === "ACCOUNT" && (
          <Account
            setStep={setStep}
            setType={setType}
            type={type}
            history={props.history}
          />
        )}
        {step === "PRIVACY" && (
          <Privacy setStep={setStep} history={props.history} type={type} />
        )}
        {step === "REGISTERLOW" && (
          <RegisterLow
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            history={props.history}
            setSecretKey={setSecretKey}
            setPublicKey={setPublicKey}
            setEthSecretKey={setEthSecretKey}
            setEthPublicKey={setEthPublicKey}
            setAlias={setAlias}
            redirectUrl={redirectUrl}
          />
        )}
        {step === "REGISTERMEDIUM" && (
          <RegisterMedium
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            history={props.history}
            setSecretKey={setSecretKey}
            setPublicKey={setPublicKey}
            setEthSecretKey={setEthSecretKey}
            setEthPublicKey={setEthPublicKey}
            setAlias={setAlias}
            redirectUrl={redirectUrl}
          />
        )}
        {step === "REGISTERHIGH" && (
          <RegisterHigh
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            history={props.history}
            setSecretKey={setSecretKey}
            setPublicKey={setPublicKey}
            setEthSecretKey={setEthSecretKey}
            setEthPublicKey={setEthPublicKey}
            setAlias={setAlias}
            redirectUrl={redirectUrl}
          />
        )}
        {step === "BLOCKCHAIN" && (
          <BlockchainCredentials
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            history={props.history}
            secretKey={secretKey}
            publicKey={publicKey}
            ethSecretKey={ethSecretKey}
            ethPublicKey={ethPublicKey}
            alias={alias}
            redirectUrl={redirectUrl}
          />
        )}
        {step === "PASSWORDRESET" && (
          <PasswordReset
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            redirectUrl={redirectUrl}
            history={props.history}
          />
        )}
      </div>
      <Footer
      //  whiteFont
      />
    </div>
  );
}

export default AuthPage;
