/* eslint-disable */ 
import axios from "axios";
import { getUserSession } from "./UserManagement"
import { niftronUserLambda, niftronTokenLambda, StellarUrl, StellarUrlTest, StellarNetwork, NiftronIssuerPublicKey, NiftronDistributorPublicKey } from "variables/constants";

import { AES, enc } from "crypto-js";
import sha256 from "sha256";
import { Server, Keypair, Asset, Transaction, Networks } from "stellar-sdk";

export async function buildRegisterXDR(primaryPublicKey, reactivate) {
  try {
    let postBody = { primaryPublicKey, merchantPublicKey: NiftronDistributorPublicKey, reactivate }
    const res = await axios.post(niftronUserLambda + "/xdrBuilder/register", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    const secondarySecretKey = res.data.metaData?.secondarySecretKey

    return {
      xdrs, secondaryPublicKey, secondarySecretKey
    };
  } catch (err) {
    return null;
  }
}
export async function signXDR(xdr, secretKey, reactivate) {
  try {
    let parsedTx;
    let publicKey;
    let server = new Server(StellarUrl);
    let keypair = Keypair.fromSecret(secretKey);

    let sourceAccount;
    try {
      parsedTx = new Transaction(xdr, Networks.PUBLIC);
      publicKey = parsedTx.source;
      sourceAccount = await server.loadAccount(keypair.publicKey());
    } catch (err) {
      try {
        server = new Server(StellarUrlTest);
        parsedTx = new Transaction(xdr, Networks.TESTNET);
        publicKey = parsedTx.source;
        sourceAccount = await server.loadAccount(keypair.publicKey());
      } catch (err2) {
        return null
      }
    }
    parsedTx.sign(keypair);
    let x = parsedTx.toEnvelope().toXDR().toString("base64");
    return x;
  } catch (err) {
    //console.log(err)
    return null;
  }
}
 
export async function signXDRTestNet(xdr, secretKey) {
  try {
    let keypair = Keypair.fromSecret(secretKey);

    let parsedTx = new Transaction(xdr, Networks.TESTNET);;
    let publicKey = parsedTx.source;;
    let server = new Server(StellarUrlTest);
    let sourceAccount;
    try {
      sourceAccount = await server.loadAccount(keypair.publicKey());
    } catch (err2) {
      return null
    }
    parsedTx.sign(keypair);
    let x = parsedTx.toEnvelope().toXDR().toString("base64");
    return x;
  } catch (err) {
    //console.log(err)
    return null;
  }
}