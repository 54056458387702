/* eslint-disable */

import React, { useState } from "react";
import StellarSdk from "stellar-sdk";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import Typography from "@material-ui/core/Typography";

import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Select from "@material-ui/core/Select";
import People from "@material-ui/icons/People";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputSelect from "components/CustomInputSelect/CustomInputSelect.js";
import { green, red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { register, checkEmail, checkAlias } from "services/UserManagement.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { useSelector } from "react-redux";

const Keypair = StellarSdk.Keypair;

const useStyles = makeStyles(styles);

function RegisterHigh(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 400);

  const loadingMessage = useSelector((state) => state.LoadingMessage);

  const [type, setType] = useState(props.type);
  const [name, setName] = useState("");

  const [loading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);

  //Errors
  const [typeError, setTypeError] = useState(false);
  const [nameError, setNameError] = useState(false);

  //Check Values
  const [checkNameError, setCheckNameError] = useState(false);
  const [checkNameSuccess, setCheckNameSuccess] = useState(false);

  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const ProceedRegistrationHigh = async (event) => {
    event.preventDefault();
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //required check
    setTypeError(type === "" ? true : false);
    setNameError(name === "" ? true : false);

    // }
    if (type != "" && name != "") {
      setLoading(true);
      //Stellar keypair
      let keypair = Keypair.random();

      //Ethereum keypair
      const Web3EthAccounts = require("web3-eth-accounts");
      const account = new Web3EthAccounts("https://cloudflare-eth.com");
      const ethKeypair = account.create();

      //Set to Parent
      props.setPublicKey(keypair.publicKey());
      props.setSecretKey(keypair.secret());
      props.setEthPublicKey(ethKeypair.address);
      props.setEthSecretKey(ethKeypair.privateKey);

      const response = await register(type, name, "", "", "", "", keypair,ethKeypair, "2")
      switch (response) {
        case 200: enqueueSnackbar("Registered Successfully", { variant: "success" });
          // if (type === "0") {
          //   props.setStep('KYC')
          // } else {
            props.setStep('BLOCKCHAIN')
          // }
          ; break;
        case 202: enqueueSnackbar("Alias Already Exists", { variant: "warning" });
          setNameError(true); break;
        case null: enqueueSnackbar("Registration Failed", { variant: "error" });
      }
      setLoading(false);
    }
  };

  const AliasMuch = async (value) => {
    if (value === name) {
      setNameLoading(true);
      const check = await checkAlias(value);
      if (check != null && !check.isAvailable) {
        setCheckNameError(true);
        setCheckNameSuccess(false);
      } else {
        setCheckNameSuccess(true);
        setCheckNameError(false);
      }
      setNameLoading(false);
    }
  };

  const aliasAPIDebounced = AwesomeDebouncePromise(AliasMuch, 500);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card className={classes[cardAnimaton]}>
            <form className={classes.form} onSubmit={ProceedRegistrationHigh}>
              <CardHeader color="info" className={classes.cardHeader}>
                <h3>REGISTRATION</h3>
              </CardHeader>
              <CardBody>
                <div
                  style={
                    loading
                      ? {
                          filter: "blur(1px)",
                          "-webkit-filter": "blur(1px)",
                        }
                      : null
                  }
                >
                  <CustomInput
                    //info
                    success={checkNameSuccess}
                    error={nameError || checkNameError}
                    labelText="Alias *"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          {nameLoading && (
                            <CircularProgress size={24} thickness={4} />
                          )}
                          {!nameLoading && checkNameSuccess && (
                            <CheckCircleOutlineIcon
                              style={{ color: green[500] }}
                            />
                          )}
                          {!nameLoading && checkNameError && (
                            <HighlightOffIcon style={{ color: red[500] }} />
                          )}
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      onChange: async function(e) {
                        setName(e.target.value);
                        setNameError(e.target.value === "" ? true : false);
                        props.setAlias(e.target.value);
                        // if (!debounced) {
                        //   setDebounced(true)
                        await aliasAPIDebounced(e.target.value);
                        // }
                      },
                      onKeyUp: async function(e) {
                        await aliasAPIDebounced(e.target.value);
                      },
                      value: name,
                    }}
                  />
                </div>

                {loading && <LinearProgress />}
                {loading && <p className={classes.divider}>{loadingMessage}</p>}
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button
                  color="info"
                  size="lg"
                  type={"submit"}
                  onClick={ProceedRegistrationHigh}
                  disabled={
                    loading ||
                    nameLoading ||
                    typeError ||
                    nameError ||
                    checkNameError
                  }
                >
                  Register{" "}
                </Button>
              </CardFooter>
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                align={"center"}
              >
                Change your mind?
                <Button
                  simple
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setStep("PRIVACY");
                  }}
                >
                  Go Back
                </Button>
              </Typography>

              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                align={"center"}
              >
                Already a niftroner?
                <Button
                  simple
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setStep("LOGIN");
                  }}
                >
                  Login
                </Button>
              </Typography>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default RegisterHigh;
